import React from 'react';
import ReactDOM from 'react-dom/client';
import { Beforeunload } from 'react-beforeunload';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
const env = process.env.REACT_APP_ENV;
if (env !== 'local') {
  // console.log = () => {};
}

const onUnload = (e) => {
  e.preventDefault();
};
window.addEventListener('beforeunload', onUnload);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Beforeunload onBeforeunload={onUnload} />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

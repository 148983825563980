import React from 'react';

import Room from './Room';
import './index.scss';

const Video = ({ room, returnToLobby }) => {
  return (
    <div className='twilio-video-wrapper'>
      <Room returnToLobby={returnToLobby} room={room} />
    </div>
  );
};

export default Video;

import { useEffect } from 'react';
import { useState } from 'react';
import Track from './Track';
import './index.scss';

const Participant = ({ participantInfo, localParticipant }) => {
  const existingPublications = Array.from(participantInfo.tracks.values());
  const existingTracks = existingPublications.map((publication) => publication.track);
  const nonNullTracks = existingTracks.filter((track) => track !== null);
  const [tracks, storeTracks] = useState(nonNullTracks);
  console.log('tracks', tracks);

  const addTrack = (track) => {
    storeTracks([...tracks, track]);
  };

  useEffect(() => {
    if (!localParticipant) {
      participantInfo.on('trackSubscribed', (track) => addTrack(track));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={localParticipant ? 'localParticipant' : 'remoteParticipant'}>
      <div className='participant' id={participantInfo.identity}>
        <div className='identity'>{participantInfo.identity}</div>
        {tracks.map((track) => (
          <Track key={track.kind} track={track} />
        ))}
      </div>
    </div>
  );
};
export default Participant;

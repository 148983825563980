import React, { useState } from 'react';
import videoOffIcon from '../../assets/videoOff.png';
import callEndIcon from '../../assets/endCall.png';
import muteIcon from '../../assets/mute.png';
import unmuteIcon from '../../assets/unmute.png';

import './index.scss';

const Controls = ({ participantInfo, onLeaveRoom }) => {
  const [videoOn, toggleVideo] = useState(true);
  const [volumeOn, toggleVolume] = useState(true);
  return (
    <div className='controls-wrapper d-flex'>
      <div className='video-controls' style={{ background: videoOn ? 'green' : '#f2f2fa' }}>
        <div
          onClick={() => {
            toggleVideo(!videoOn);

            if (videoOn) {
              participantInfo.videoTracks.forEach((track) => {
                track.track.disable();
              });
            } else {
              participantInfo.videoTracks.forEach((track) => {
                track.track.enable();
              });
            }
          }}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        >
          <img src={videoOffIcon} alt='video-icon' style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
      <div className='mute-controls'>
        <div
          onClick={() => {
            toggleVolume(!volumeOn);
            console.log('participantInfo', participantInfo);
            if (volumeOn) {
              participantInfo.audioTracks.forEach((track) => {
                track.track.disable();
              });
            } else {
              participantInfo.audioTracks.forEach((track) => {
                track.track.enable();
              });
            }
          }}
          style={{ width: '24px', height: '24px', cursor: 'pointer' }}
        >
          <img
            src={volumeOn ? unmuteIcon : muteIcon}
            alt='mic-icon'
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>

      <div className='end-call-controls'>
        <div onClick={onLeaveRoom} style={{ width: '24px', height: '24px', cursor: 'pointer' }}>
          <img src={callEndIcon} alt='end-call-icon' style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
    </div>
  );
};

export default Controls;

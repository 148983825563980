import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from '../../components/Header';
import { AppContextProvider } from '../../utils/appContext';
import { routes } from '../../utils/constants';
import { setItemInLocalStorage } from '../../utils/helpers';
import './App.scss';

function App() {
  const totalSteps = 12;
  const [stepNo, setStepNo] = useState(1);
  const [logoutState, setLogoutState] = useState(false);
  const renderRoutepages = () =>
    routes.map(({ key, page, path }) => <Route key={`route-${key}`} path={path} element={page} />);
  const handleLogOff = () => {
    // to reset token in local storage
    setItemInLocalStorage('token', '');
    setItemInLocalStorage('accountId', '');
    setLogoutState(true);
    setTimeout(() => {
      setLogoutState(false);
    }, 300);
  };

  return (
    <AppContextProvider
      value={{
        logoutState,
        stepNo,
        setStepNo,
      }}
    >
      <Header handleLogOff={handleLogOff} totalSteps={totalSteps} stepNo={stepNo} />
      <Routes>{renderRoutepages()}</Routes>
    </AppContextProvider>
  );
}

export default App;

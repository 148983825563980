import React, { useState } from 'react';

import InsuranceForm from './InsuranceForm';
import Scanner from '../../../../components/Scanner';

import './index.scss';

const Insurance = ({ showLoader, hideLoader, selectedInsurance, skipInsurance }) => {
  const [showScanner, setScannerState] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState({
    insuranceType: {
      name: 'insuranceType',
      type: 'text',
      value: '',
      dirty: false,
      error: false,
      placeholder: 'Please choose insurance option',
    },
    insuranceProvider: {
      name: 'insuranceProvider',
      type: 'text',
      value: '',
      dirty: false,
      error: false,
      placeholder: 'Insurance provider',
    },
    membershipId: {
      name: 'membershipId',
      type: 'text',
      value: '',
      dirty: false,
      error: false,
      placeholder: 'Membership/Subscriber ID',
    },
    groupId: {
      name: 'groupId',
      type: 'text',
      value: '',
      dirty: false,
      error: false,
      placeholder: 'Group Id',
    },
    firstname: {
      name: 'firstname',
      type: 'text',
      value: '',
      dirty: false,
      error: false,
      placeholder: 'First Name',
    },
    lastname: {
      name: 'lastname',
      type: 'text',
      value: '',
      dirty: false,
      error: false,
      placeholder: 'Last Name',
    },
    birthdate: {
      value: '',
      error: false,
      placeholder: 'Policy holder birthdate',
    },
    gender: {
      value: '',
      placeholder: 'Gender',
    },
    relationship: {
      value: '',
      placeholder: 'Relationship',
    },
    policyHolder: {
      value: 'no',
    },
  });
  const handleInsuranceInfoChange = (data) => {
    setInsuranceInfo({ ...insuranceInfo, ...data });
  };

  const resetCardScanner = () => {
    document.getElementById('root').style.display = 'block';
    document.getElementById('scanner-root').innerHTML = '';
  };

  const closeAudioAndVideo = () => {
    const video = document.querySelector('video');

    for (const track of video.srcObject.getTracks()) {
      console.log('track', track, track.stop);

      track.stop();
    }
  };
  const onBack = () => {
    closeAudioAndVideo();

    setTimeout(() => {
      resetCardScanner();
      setScannerState(false);
    }, 1000);
  };
  const onScanSuccess = async (card = {}) => {
    try {
      console.log('onScanSuccess', card);

      const {
        card_specific_id,
        dependent_names = [],
        group_number,
        member_name,
        member_number,
        payer_name,
        plan_id,
        rx_bin,
        rx_pcn,
      } = card;
      let policyHolderFirstName = '';
      let policyHolderLastName = '';

      if (member_name?.value)
        [policyHolderFirstName = '', policyHolderLastName = ''] = member_name?.value?.split(' ');

      handleInsuranceInfoChange({
        policyHolder: {
          value: policyHolderFirstName ? 'yes' : 'no',
        },
        insuranceType: {
          ...insuranceInfo.insuranceType,
          value: '0',
        },
        ...(member_number && {
          membershipId: {
            name: 'membershipId',
            type: 'text',
            value: member_number.value,
            dirty: false,
            error: false,
            placeholder: 'Membership/Subscriber ID',
          },
        }),
        ...(group_number && {
          groupId: {
            name: 'groupId',
            type: 'text',
            value: group_number?.value,
            dirty: false,
            error: false,
            placeholder: 'Group Id',
          },
        }),
        ...(policyHolderFirstName && {
          firstname: {
            name: 'firstname',
            type: 'text',
            value: policyHolderFirstName,
            dirty: false,
            error: false,
            placeholder: 'First Name',
          },
        }),
        ...(policyHolderLastName && {
          lastname: {
            name: 'lastname',
            type: 'text',
            value: policyHolderLastName,
            dirty: false,
            error: false,
            placeholder: 'Last Name',
          },
        }),
        ...(payer_name && {
          insuranceProvider: {
            name: 'insuranceProvider',
            type: 'text',
            value: payer_name?.value,
            dirty: false,
            error: false,
            placeholder: 'Insurance provider',
          },
        }),
      });
      //TODO - need to remove this
      console.log('onScanSuccess info', {
        policyHolder: {
          value: policyHolderFirstName ? 'yes' : 'no',
        },
        ...(member_number && {
          membershipId: {
            name: 'membershipId',
            type: 'text',
            value: member_number.value,
            dirty: false,
            error: false,
            placeholder: 'Membership/Subscriber ID',
          },
        }),
        ...(group_number && {
          groupId: {
            name: 'groupId',
            type: 'text',
            value: group_number?.value,
            dirty: false,
            error: false,
            placeholder: 'Group Id',
          },
        }),
        ...(policyHolderFirstName && {
          firstname: {
            name: 'firstname',
            type: 'text',
            value: policyHolderFirstName,
            dirty: false,
            error: false,
            placeholder: 'First Name',
          },
        }),
        ...(policyHolderLastName && {
          lastname: {
            name: 'lastname',
            type: 'text',
            value: policyHolderLastName,
            dirty: false,
            error: false,
            placeholder: 'Last Name',
          },
        }),
        ...(payer_name && {
          insuranceProvider: {
            name: 'insuranceProvider',
            type: 'text',
            value: payer_name?.value,
            dirty: false,
            error: false,
            placeholder: 'Insurance provider',
          },
        }),
      });
    } catch (err) {
      console.error('Error in scanning card', err);
    }
    onBack();
  };
  const onScanError = (err) => {
    onBack();
    console.error('On Scan Error', err);
  };
  const onScanCard = () => {
    console.log('called');
    setScannerState(true);
    setTimeout(() => (document.getElementById('root').style.display = 'none'), 700);
  };
  return showScanner ? (
    <Scanner onBack={onBack} onScanSuccess={onScanSuccess} onScanError={onScanError} />
  ) : (
    <>
      <InsuranceForm
        addInsurance={selectedInsurance}
        showLoader={showLoader}
        hideLoader={hideLoader}
        skipInsurance={skipInsurance}
        onScanCard={onScanCard}
        handleInsuranceInfoChange={handleInsuranceInfoChange}
        insuranceInfo={insuranceInfo}
        setInsuranceInfo={setInsuranceInfo}
      />
    </>
  );
};

export default Insurance;

import { MOBILE_REGEXES, VALIDATION_REGEX } from './constants';

const getItemFromLocalStorage = (key) => {
  if (window.localStorage) return window.localStorage.getItem(key) || '';
  return '';
};
const setItemInLocalStorage = (key, value) => {
  if (window.localStorage) window.localStorage.setItem(key, value);
};
const getToken = () => {
  return getItemFromLocalStorage('token');
};
const formatBirthDate = (format, date) => {
  const [day, month, year] = date.split('-');
  return format.replace('MM', month).replace('DD', day).replace('YYYY', year);
};
const validateMobile = (countryCode, mobile) => {
  switch (countryCode) {
    case '+91':
      return MOBILE_REGEXES['in'].test(mobile);
    case '+1':
      return MOBILE_REGEXES['us'].test(mobile);
    default:
      return MOBILE_REGEXES['in'].test(mobile);
  }
};
const validateForm = (data) => {
  const output = {};
  Object.keys(data).map((name) => {
    const { value } = data[name];
    if (name === 'mobile') {
      const countryCode = data['countryCode'].value;
      const mobile = value;
      output[name] = {
        isValid: validateMobile(countryCode, mobile),
      };
    } else {
      if (!VALIDATION_REGEX[name]) {
        // if regex not available then return true
        output[name] = {
          isValid: true,
        };
      } else {
        output[name] = {
          isValid: VALIDATION_REGEX[name].test(value),
        };
      }
    }
    return null;
  });
  return output;
};
const isFormValid = (data) => {
  const validations = Object.keys(data).map((type) => data[type].isValid);
  return !validations.some((validation) => !validation);
};
const updateFormdata = (formData, validationOutput) => {
  const newformData = { ...formData };
  Object.keys(validationOutput).map(
    (key) => (newformData[key].error = !validationOutput[key].isValid),
  );
  return newformData;
};
const isEmpty = (value) => !value || value?.length === 0;
function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
const sortByKey = (arr, key) => {
  return arr?.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    else if (a[key] > b[key]) return 1;
    else return 0;
  });
};

const addDays = function (currentDate, days) {
  var date = new Date(currentDate);
  date.setDate(date.getDate() + days);
  return date;
};

export {
  getItemFromLocalStorage,
  setItemInLocalStorage,
  getToken,
  formatBirthDate,
  validateForm,
  isFormValid,
  updateFormdata,
  isEmpty,
  debounce,
  validateMobile,
  sortByKey,
  addDays,
};

import './index.scss';

const Loader = () => (
  <div className='loader-wrapper center'>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
    <div className='wave'></div>
  </div>
);

export default Loader;

import React from 'react';

import { ServiceButton } from '../../../../components/Button';
import './index.scss';

const CallWaitingPage = ({ goToDashboard }) => {
  return (
    <div className='call-waiting-wrapper d-flex flex-column align-items-center'>
      <div
        className='waiting-room-header'
        style={{ marginBottom: 24, color: '#ffffff', fontWeight: 'bold' }}
      >
        Waiting Room
      </div>
      <span className='waiting-room-description'>
        A provider will be with you shortly. Feel free to leave the waiting room or close the app.
        You will not lose your place in queue. Average wait time is 2-5 minutes.
      </span>
      <div>
        <ServiceButton style={{ marginTop: 40 }} onClick={goToDashboard}>
          <div style={{ marginRight: 10, width: 120 }}>GO TO HOME</div>
        </ServiceButton>
      </div>
    </div>
  );
};

export default CallWaitingPage;

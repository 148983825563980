import React from 'react';
import nextArrow from '../../assets/next_arrow.svg';

import './index.scss';

const Button = ({ type, children, className = '', onClick, style = {}, ...rest }) => {
  switch (type) {
    case 'secondary':
      return (
        <button
          className={`secondary-btn-wrapper ff-museosans-reg ${className}`}
          onClick={onClick}
          style={style}
          {...rest}
        >
          {children}
        </button>
      );

    default:
      return (
        <button
          className={`btn-wrapper ff-museosans-reg ${className}`}
          onClick={onClick}
          style={style}
          {...rest}
        >
          {children}
        </button>
      );
  }
};

const ServiceButton = ({ children, className = '', divStyle, ...restProps }) => (
  <Button className={`service-btn-wrapper ${className}`} {...restProps}>
    <div
      style={{
        padding: 5,
        ...divStyle,
      }}
      className='d-flex'
    >
      {children}

      <img style={{ marginLeft: 10 }} src={nextArrow} alt='next-arrow' />
    </div>
  </Button>
);
export default Button;
export { ServiceButton };

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import { API_URLS } from '../../../../utils/constants';
import { validateMobile } from '../../../../utils/helpers';
import axios from '../../../../utils/axiosWrapper';
import Text from '../../../../components/Text';
import Otp from '../../../../components/Otp';
import {
  isFormValid,
  setItemInLocalStorage,
  updateFormdata,
  validateForm,
} from '../../../../utils/helpers';
import './index.scss';

const Verification = ({ showLoader, hideLoader, onSuccess }) => {
  const [showOtpVerify, setOtpVerify] = useState(false);
  const [formData, setFormData] = React.useState({
    countryCode: {
      value: '+1',
      dirty: false,
      error: false,
      type: 'text',
      name: 'countryCode',
    },
    mobile: {
      value: '',
      dirty: false,
      error: false,
      type: 'number',
      name: 'mobile',
    },
  });
  const [otp, storeOtp] = useState('');
  const requestOtp = async () => {
    const validationOutput = validateForm({
      countryCode: formData.countryCode,
      mobile: formData.mobile,
    });
    console.log('validationOutput', validationOutput, isFormValid(validationOutput));
    if (isFormValid(validationOutput)) {
      showLoader();
      const res = await axios({
        method: 'POST',
        url: API_URLS.requestOtp,
        data: {
          countrycode: formData.countryCode.value,
          phonenumber: formData.mobile.value,
        },
      });
      if (!res.isError) {
        setOtpVerify(true);
      } else {
        toast.error(res?.errorResponse?.data?.status?.message?.phonenumber?.[0]);
      }
      hideLoader();
    } else {
      setFormData(updateFormdata(formData, validationOutput));
      toast.error('Invalid mobile or country code');
    }
  };

  const verifyOtp = async () => {
    const validationOutput = validateForm({
      otp: { value: otp, name: 'otp' },
    });
    if (isFormValid(validationOutput)) {
      showLoader();
      const res = await axios({
        method: 'POST',
        url: API_URLS.verifyOtp,
        data: {
          countrycode: formData.countryCode.value,
          phonenumber: formData.mobile.value,
          otp,
        },
      });
      hideLoader();

      if (!res.isError) {
        setOtpVerify(true);
        setItemInLocalStorage('token', res.session.token);
        setItemInLocalStorage('accountId', res.data.id);
        onSuccess({ profileData: res.data });
      }
    } else {
      toast.error('Invalid otp');
    }
  };

  const handleChange = (data) => {
    setFormData({ ...formData, ...data });
  };
  const handleOtpChange = (value) => {
    storeOtp(value);
  };
  console.log('formdata', formData, otp);
  return (
    <div className='verification-form-wrapper m-t-24'>
      {showOtpVerify ? (
        <Text className='verification-screen-title'>
          Enter the otp sent to your no
          {`${formData['countryCode'].value}${formData['mobile'].value}`}
        </Text>
      ) : (
        <Text className='verification-screen-title'>Now, let’s create your profile</Text>
      )}
      <div className='d-flex verification-form justify-content-center'>
        {showOtpVerify ? (
          <Otp onChange={handleOtpChange} onHandleEnter={verifyOtp} />
        ) : (
          <>
            <Input
              state={formData['countryCode']}
              className='country-code'
              placeholder='Country code'
              onChange={handleChange}
              disabled={showOtpVerify}
              errorMessage='Please enter valid country code'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  requestOtp();
                }
              }}
            />
            <Input
              state={formData['mobile']}
              placeholder='Mobile'
              onChange={handleChange}
              disabled={showOtpVerify}
              errorMessage='Please enter valid mobile'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  requestOtp();
                }
              }}
              validateInput={(mobile) => {
                return {
                  [formData['mobile'].name]: {
                    isValid: validateMobile(formData['countryCode'].value, mobile),
                  },
                };
              }}
            />
          </>
        )}

        <div className='otp-button-wrapper'>
          {showOtpVerify ? (
            <Button onClick={verifyOtp}>Verify OTP</Button>
          ) : (
            <Button onClick={requestOtp}>Request OTP</Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default Verification;

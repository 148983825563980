import { useState } from 'react';
import Button from '../../../../components/Button';
import { endUserLicenseURL, privacyPolicyURL } from '../../../../utils/constants';
import './index.scss';
const UserAgreement = ({ onSubmit }) => {
  const [agreementState, onChooseAgreement] = useState(false);
  return (
    <div
      className='d-flex flex-column align-items-center'
      style={{
        margin: 24,
      }}
    >
      <h3
        style={{
          fontWeight: 600,
        }}
        className='user-agreement-heading'
      >
        We’re committed to the security of your health information
      </h3>
      <span className='user-agreement-description'>
        All your information is encrypted and stored securely and our platform compiles with HIPAA
        requirements
      </span>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginTop: '24px',
        }}
      >
        <input
          type='checkbox'
          onChange={(e) => {
            onChooseAgreement(e.target.checked);
          }}
          value={agreementState}
        />
        <div style={{ marginLeft: 16, maxWidth: 500 }}>
          By checking the box and pressing “Submit”, you consent to recieve text messages from or on
          behalf of HealthX about your care at the phone number provided and agree to the
          <a className='agreement-link' href={endUserLicenseURL} target='_blank' rel='noreferrer'>
            End User License Agreement
          </a>
          and acknowledge receipt of the{' '}
          <a className='agreement-link' href={privacyPolicyURL} target='_blank' rel='noreferrer'>
            Privacy Policy
          </a>
          and Notice of Privacy Practices.
        </div>
      </div>
      <Button className='submit-btn-wrapper' disabled={!agreementState} onClick={onSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default UserAgreement;

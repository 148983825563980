import React, { useState, useEffect } from 'react';
import './index.scss';
import logo from '../../assets/logo_white.svg';
import logoff from '../../assets/logoff.png';
import StepSlider from '../StepSlider';
import { debounce } from '../../utils/helpers';

const Header = ({ stepNo, totalSteps, handleLogOff }) => {
  const [stepperWidth, setStepperWidth] = useState(800);
  const handleResize = debounce(() => {
    setStepperWidth(window.screen.width - 50);
  }, 1000);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='header-wrapper'>
      <header>
        <ul className='d-flex header align-items-center'>
          <div style={{ marginLeft: 12 }}>
            <img
              src={logo}
              className='App-logo'
              alt='logo'
              style={{ height: '16px', width: '140px' }}
            />
          </div>
          <div className='logout-icon-wrapper' onClick={handleLogOff}>
            <img
              src={logoff}
              className='App-logo'
              alt='logo'
              style={{ height: '100%', width: '100%' }}
            />
          </div>
        </ul>
      </header>
      <div className='d-flex flex-column'>
        {/* <span className='p-2 text-center ff-museosans-sm' style={{ color: 'white' }}>
          STEP {stepNo >= 11 ? 11 : stepNo} / {totalSteps}
        </span> */}
        <StepSlider
          // width={`${stepperWidth}px`}
          stepperWidth={stepperWidth}
          totalSteps={totalSteps}
          completedSteps={stepNo}
        />
      </div>
    </div>
  );
};

export default Header;

import { useEffect, useRef, useState } from 'react';

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

async function handlePlaceSelect(updateQuery, updateAddressForm) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  var componentForm = {
    street_number: 'short_name',
    sublocality_level_1: 'long_name',
    sublocality_level_2: 'long_name',
    sublocality_level_3: 'long_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
  };

  updateQuery(query);
  let address1 = ' ',
    address2 = ' ',
    city = ' ',
    state = ' ',
    zipcode = ' ',
    stateCode = '';
  const lat = addressObject.geometry.location.lat();
  const long = addressObject.geometry.location.lng();

  console.log('addressObject', addressObject);
  for (var i = 0; i < addressObject.address_components.length; i++) {
    let addressType = addressObject.address_components[i].types[0];
    if (addressType === 'street_number' || addressType === 'route') {
      address1 = addressObject.name;
    }
    if (addressType === 'administrative_area_level_1')
      stateCode = addressObject.address_components[i].short_name;
    if (
      addressType === 'sublocality' ||
      addressType === 'sublocality_level_1' ||
      addressType === 'sublocality_level_2'
    ) {
      if (addressObject.address_components[i][componentForm[addressType]] !== '') {
        address2 = addressObject.address_components[i][componentForm[addressType]] || ' ';
        address1 = address1 ? address1 : address2;
      }
    }

    if (addressType === 'locality') {
      city = addressObject.address_components[i][componentForm[addressType]] || ' ';
    }

    if (addressType === 'administrative_area_level_1') {
      state = addressObject.address_components[i][componentForm[addressType]] || ' ';
    }

    if (addressType === 'postal_code') {
      zipcode = addressObject.address_components[i][componentForm[addressType]] || ' ';
    }
  }

  updateAddressForm({
    address1,
    address2,
    city,
    state,
    zipcode,
    lat: parseFloat(lat).toFixed(2),
    long: parseFloat(long).toFixed(2),
    stateCode,
    formattedAddress: addressObject?.formatted_address,
  });
}

function handleScriptLoad(updateQuery, updateAddressForm, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(updateQuery, updateAddressForm),
  );
}

export default function SearchLocation({ updateAddressForm }) {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAGlxWQxoiG_iMUqqbSZrRn-7hKkZUDzb8&libraries=places`,
      () => handleScriptLoad(setQuery, updateAddressForm, autoCompleteRef),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='search-location-input'>
      <input
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        addressObjholder='Enter a City'
        placeholder='Search address'
        value={query}
        className='search-places-input'
      />
    </div>
  );
}

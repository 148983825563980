import React from 'react';
import { useEffect } from 'react';

const Track = ({ track }) => {
  const trackRef = React.createRef();
  useEffect(() => {
    if (track !== null) {
      const child = track.attach();
      if (!trackRef.current.classList.contains(track.kind)) {
        trackRef.current.classList.add(track.kind);
        trackRef.current.appendChild(child);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className='track' ref={trackRef} />;
};
export default Track;

import { useState } from 'react';
import { toast } from 'react-toastify';
import AddressForm from './AddressForm';
import Arrow from '../../../../components/Arrow';
import Text from '../../../../components/Text';
import addAddressIcon from '../../../../assets/addAddress.svg';
import './index.scss';

const AddressBox = ({ address1, address2, city, zipcode, state, isSelected, onClick }) => (
  <div
    className='d-flex flex-column address-box cursor-pointer'
    style={{
      boxShadow: isSelected ? '0px 3px 3px #e8e8e9' : 'none',
      border: isSelected ? '1px solid #EE1C2C' : '1px solid #000000',
    }}
    onClick={onClick}
  >
    {address1 && <span>{address1}</span>}
    {address2 && <span>{address2}</span>}
    {city && <span>{city}</span>}
    {state && <span>{state}</span>}
    {zipcode && <span>{zipcode}</span>}
  </div>
);
const AddAddress = ({ onClick }) => (
  <div
    className='d-flex flex-column justify-content-center align-items-center address-box  cursor-pointer'
    onClick={onClick}
  >
    <span style={{ width: 24, height: 24 }}>
      <img src={addAddressIcon} alt='add-address-img' />
    </span>
    ADD ADDRESS
  </div>
);
const Address = ({ showLoader, hideLoader, addresses = [], selectedAddress }) => {
  const [selectedAddressIndex, setAddressIndex] = useState(0);
  const [addressList, setAddressList] = useState(addresses);
  const [showAddressForm, setAddressFormVisibility] = useState(false);
  const closeAddressForm = () => setAddressFormVisibility(false);
  const addAddress = (newAddress) => {
    setAddressList([...addressList, newAddress]);
    closeAddressForm();
  };
  const handleNext = () => {
    selectedAddress(addressList[selectedAddressIndex]);
  };
  if (showAddressForm)
    return (
      <AddressForm
        showLoader={showLoader}
        hideLoader={hideLoader}
        addAddress={addAddress}
        onBack={closeAddressForm}
      />
    );
  return (
    <div className='m-t-24'>
      <Text>Choose address</Text>

      <div className='d-flex flex-wrap address-box-wrapper'>
        {addressList?.map(
          ({ address_1: address1, address_2: address2, city, state, zip_code: zipcode }, index) => (
            <AddressBox
              key={`address-box-${index}`}
              address1={address1}
              address2={address2}
              city={city}
              state={state}
              zipcode={zipcode}
              isSelected={index === selectedAddressIndex}
              onClick={() => setAddressIndex(index)}
            />
          ),
        )}
        <AddAddress
          onClick={() => {
            setAddressFormVisibility(true);
          }}
        />
      </div>
      <div className='d-flex align-items-center justify-content-center'>
        <Arrow
          onClick={
            addressList.length > 0
              ? handleNext
              : () => {
                  toast.error('Please create address');
                }
          }
        />
      </div>
    </div>
  );
};

export default Address;

import React from 'react';
import rightArrow from '../../assets/right-arrow.svg';

const Arrow = ({ onClick }) => {
  return (
    <div className='d-flex align-items-center justify-content-center'>
      <div className='m-t-24 d-flex align-items-center cursor-pointer' onClick={onClick}>
        <span className='text-danger ff-museosans-med m-1'>Next</span>
        <img src={rightArrow} alt='right-arrow' style={{ width: 15, height: 15 }} />
      </div>
    </div>
  );
};

export default Arrow;

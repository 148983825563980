import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import 'react-datepicker/dist/react-datepicker.css';

import Text from '../../../../components/Text';
import Button, { ServiceButton } from '../../../../components/Button';
import { addDays } from '../../../../utils/helpers';
import './index.scss';

const Appointment = ({
  isTeleconsultationAvailable,
  isHomeVisitAvailable,
  surveyScoreInfo,
  submitAppointment,
  goToDashboard,
}) => {
  const [selectedSlot, setSelectedSlot] = useState();
  const minDate = useRef(addDays(new Date(), 1));
  const roundToNearest15 = (date) => {
    const minutes = 15;
    const ms = 1000 * 60 * minutes;
    return new Date(Math.round(date / ms) * ms);
  };

  const showOnDemandCare = isTeleconsultationAvailable;
  return (
    <div className='m-t-24'>
      {surveyScoreInfo?.message && (
        <Text className='appointment-page-heading'>{surveyScoreInfo?.message}</Text>
      )}

      {surveyScoreInfo?.message === 'You have determined to be at high risk' && (
        <div className='appointment-page-description'>
          Connect with one of our Medical Professionals now
        </div>
      )}
      {!isTeleconsultationAvailable && !isHomeVisitAvailable ? (
        <div className='d-flex flex-column' style={{ margin: '24px' }}>
          <div style={{ margin: '12px 0', textAlign: 'center' }}>
            Service is currently not available
          </div>
          <Button onClick={goToDashboard}>Home</Button>
        </div>
      ) : (
        <div className='d-flex flex-column align-items-center appointment-field-wrapper'>
          {isHomeVisitAvailable && (
            <div className='date-picker-wrapper-appointment'>
              <DatePicker
                selected={selectedSlot}
                onChange={(date) => setSelectedSlot(date)}
                showTimeSelect
                placeholderText={'Select date and time'}
                dateFormat='Pp'
                minDate={minDate.current}
                minTime={setHours(setMinutes(new Date(), 0), 9)}
                maxTime={setHours(setMinutes(new Date(), 0), 18)}
              />
            </div>
          )}

          {showOnDemandCare && (
            <div
              className='d-flex flex-column'
              style={{
                justifyContent: 'center',
                marginTop: 16,
                width: 260,
              }}
            >
              <ServiceButton
                onClick={() => {
                  const date = new Date();
                  const day = date.getDate();
                  const month = date.getMonth();
                  const year = date.getFullYear();
                  const hours = date.getHours();
                  const minutes = date.getMinutes();
                  console.log(
                    'Date.UTC(year, month, day, hours, minutes, 0)',
                    Date.UTC(year, month, day, hours, minutes, 0),
                  );
                  submitAppointment({
                    appointmentTime:
                      roundToNearest15(new Date(year, month, day, hours, minutes, 0)) / 1000,
                    source: 'onDemandCall',
                  });
                }}
              >
                <div>ON DEMAND CARE</div>
              </ServiceButton>
            </div>
          )}

          {isHomeVisitAvailable && (
            <Button
              style={{ marginTop: 24, width: '260px' }}
              onClick={() => {
                if (!selectedSlot) {
                  toast.error('Please select slot');
                } else {
                  const date = selectedSlot;
                  const day = date.getDate();
                  const month = date.getMonth();
                  const year = date.getFullYear();
                  const hours = date.getHours();
                  const minutes = date.getMinutes();

                  submitAppointment({
                    appointmentTime:
                      roundToNearest15(new Date(year, month, day, hours, minutes, 0)) / 1000,
                    source: isTeleconsultationAvailable ? 'scheduleCall' : '',
                  });
                }
              }}
            >
              Schedule call
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Appointment;

import React from 'react';
import { ServiceButton } from '../../../../components/Button';
import Text from '../../../../components/Text';
import './index.scss';

const UserType = ({ goToStep2 }) => {
  return (
    <div>
      <Text>Are you an established patient?</Text>
      <div className='d-flex welcome-screen-btn-wrapper'>
        <ServiceButton className='d-flex' onClick={goToStep2}>
          <span>ESTABLISHED PATIENT</span>
        </ServiceButton>
        <ServiceButton className='d-flex' onClick={goToStep2}>
          <span>NEW ACCOUNT SET UP</span>
        </ServiceButton>
      </div>
    </div>
  );
};

export default UserType;

import axios from 'axios';
import { getToken } from './helpers';

axios.defaults.baseURL = (function () {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case 'dev':
      return 'https://dev.health-x.com';
    case 'stage':
      return 'https://stage.health-x.com';
    case 'production':
      return 'https://app.health-x.com/';
    case 'local':
    default:
      return 'https://stage.health-x.com';
  }
})();
// axios.defaults.baseURL = 'https://dev.health-x.com';

axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

axios.interceptors.request.use(
  function (config) {
    // add token in headers in available
    const token = getToken();
    const { ignoreToken = false } = config;
    if (token && !ignoreToken) {
      config.headers = { ...config.headers, Authorization: `Token ${token}` };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    // Do something with response data like console.log, change header, or as we did here just added a conditional behaviour, to change the route or pop up an alert box, based on the reponse status
    if (response.status === 200 || response.status === 201) {
      return { ...response.data, isError: false };
    }
    return response;
  },
  function (error) {
    console.log('error', error);
    // Do something with response error
    return {
      error,
      errorResponse: error?.response,
      isError: true,
    };
  },
);

export default axios;

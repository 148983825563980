import { useEffect, useState, useRef } from 'react';

import './index.scss';
import OtpInput from './OtpInput';

const Otp = ({ totalInputs = 4, onChange, onHandleEnter }) => {
  const [activeInput, setActiveInput] = useState(0);
  const [values, storeInputs] = useState([]);
  const elements = useRef();

  const moveLeft = () => {
    if (activeInput > 0) setActiveInput(activeInput - 1);
  };
  const moveRight = () => {
    if (activeInput < totalInputs - 1) setActiveInput(activeInput + 1);
  };
  const focusOnActiveInput = () => {
    if (elements.current) elements.current[activeInput].focus();
  };

  const onEnterValue = ({ value, currentInputIndex }) => {
    values[currentInputIndex] = value;
    storeInputs([...values]);
    onChange(values.join(''));
    if (value === '') return; // user is trying to erase otp in input
    if (currentInputIndex < totalInputs - 1) {
      setTimeout(moveRight, 1);
    }
  };

  useEffect(() => {
    focusOnActiveInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInput]);

  useEffect(() => {
    elements.current = document.getElementsByClassName('otp-input-field');
    focusOnActiveInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log('otpvalues', values);
  const renderInputs = () =>
    Array(4)
      .fill(0)
      .map((key, index) => (
        <OtpInput
          index={index}
          setActiveInput={setActiveInput}
          onEnterValue={onEnterValue}
          moveLeft={moveLeft}
          moveRight={moveRight}
          onHandleEnter={onHandleEnter}
        />
      ));
  return <div className='d-flex'>{renderInputs()}</div>;
};

export default Otp;

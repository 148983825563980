import './index.scss';
const RadioGroup = ({ name, values, selectedValue, onChange, isError, errorMessage = '' }) => {
  const onChangeValue = (event) => {
    onChange(event.target.value, {
      name,
    });
  };
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex'>
        {values.map((value, index) => (
          <span className='radio-wrapper d-flex align-items-center' key={`${name}-${value}`}>
            <label htmlFor={`${name}-${value}`}>{value}</label>
            <input
              type='radio'
              name={name}
              value={value}
              id={`${name}-${value}`}
              checked={value === selectedValue}
              onChange={onChangeValue}
            />
          </span>
        ))}
      </div>
      {isError && <div className='text-danger error-span'>{errorMessage}</div>}
    </div>
  );
};
export default RadioGroup;

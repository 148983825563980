import React from 'react';
import { validateForm } from '../../utils/helpers';
import './index.scss';

const Input = ({
  className = '',
  state,
  placeholder,
  disabled = false,
  style = {},
  hideError = false,
  onChange,
  errorMessage = '',
  validateInput = null,
  ...rest
}) => {
  const { type, value, name, error } = state;
  // const [inputValue, setInputValue] = React.useState(value);
  const handleChange = (event) => {
    // setInputValue(event.target.value);
    const validationOutput = validateInput
      ? validateInput(event.target.value)
      : validateForm({
          [state.name]: { ...state, value: event.target.value },
        });

    onChange({
      [name]: {
        ...state,
        dirty: true,
        error: !validationOutput[state.name].isValid,
        value: event.target.value,
      },
    });
  };

  return (
    <div className='d-flex flex-column position-relative'>
      <input
        className={`input-wrapper ${className}`}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
        style={style}
        {...rest}
      />
      {!hideError && error && <span className='error-span position-relative'>{errorMessage}</span>}
    </div>
  );
};

export default Input;

import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../../components/Button';
import { API_URLS } from '../../../../utils/constants';
import axios from '../../../../utils/axiosWrapper';
import SearchLocation from './SearchLocation';

import './index.scss';

const AddressForm = ({ showLoader, hideLoader, addAddress, onBack }) => {
  const [addressInfo, setAddressInfo] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    lat: '',
    long: '',
    formattedAddress: '',
    stateCode: '',
  });

  const createAddress = async () => {
    console.log('addressInfo', addressInfo);
    if (!addressInfo?.city) {
      toast.error('Please add address');
      return;
    }
    showLoader();
    const res = await axios({
      method: 'POST',
      url: API_URLS.addressApi,
      data: {
        address_1: addressInfo.address1,
        address_2: addressInfo.address2,
        city: addressInfo.city,
        state: addressInfo.state,
        zip_code: addressInfo.zipcode,
        latitude: addressInfo.lat,
        longitude: addressInfo.long,
        formatted_address: addressInfo.formattedAddress,
        state_code: addressInfo.stateCode,
      },
    });
    if (!res.isError) {
      addAddress(res.data);
    }
    hideLoader();
  };
  const updateAddressForm = (addressObj) => {
    console.log('addressObj in updateAddressForm', addressObj);
    setAddressInfo(addressObj);
  };

  return (
    <div className='m-t-24'>
      <div className='text-center ff-museosans-bold m-3'>Add address</div>
      <div className='search-location-wrap'>
        <SearchLocation updateAddressForm={updateAddressForm} />
      </div>
      <div className='d-flex align-items-center justify-content-center'>
        <Button style={{ marginRight: '8px', width: 100 }} onClick={createAddress}>
          Add
        </Button>
        <Button type='secondary' style={{ marginRight: '8px', width: 80 }} onClick={onBack}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default AddressForm;

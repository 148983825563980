import './index.scss';
const Text = ({ children, type, className = '', ...rest }) => {
  switch (type) {
    case 'large':
    default:
      return (
        <div className={`text-center ff-museosans-sm large-text ${className}`} {...rest}>
          {children}
        </div>
      );
  }
};
Text.defaultProps = {
  type: 'large',
};
export default Text;

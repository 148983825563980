import { useState, useEffect } from 'react';
import Controls from './Controls';
import Participant from './Participant';

const Room = ({ room, returnToLobby }) => {
  const [remoteParticipants, storeParticipants] = useState(Array.from(room.participants.values()));
  console.log('Room', room, room.participants.values(), remoteParticipants);

  const addParticipant = (participant) => {
    console.log(`${participant.identity} has joined the room.`);
    storeParticipants([...remoteParticipants, participant]);
  };

  const removeParticipant = (participant) => {
    console.log(`${participant.identity} has left the room`);
    storeParticipants(remoteParticipants.filter((p) => p.identity !== participant.identity));
  };

  const onLeaveRoom = () => {
    if (room) {
      room.disconnect();
      returnToLobby();
    }
  };

  useEffect(() => {
    room.on('participantConnected', (participant) => addParticipant(participant));
    room.on('participantDisconnected', (participant) => removeParticipant(participant));

    window.addEventListener('beforeunload', onLeaveRoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='participants'>
      <Participant
        key={room.localParticipant.identity}
        localParticipant={true}
        participantInfo={room.localParticipant}
      />
      {remoteParticipants.map((participantInfo) => (
        <div className='remote-participants-wrapper'>
          <Participant
            key={participantInfo.identity}
            localParticipant={false}
            participantInfo={participantInfo}
          />
          <Controls onLeaveRoom={onLeaveRoom} participantInfo={room.localParticipant} />
        </div>
      ))}
    </div>
  );
};

export default Room;

import React from 'react';
import Text from '../../../../components/Text';
import rightArrow from '../../../../assets/right-arrow.svg';

import './index.scss';

const ThankyouPage = ({ goToDashboard }) => {
  const renderSteps = () =>
    [
      {
        stepName: 'Appointment Fulfillment',
        content:
          'A team member is reviewing your order. They will reach out to get all information required for the appointment',
      },
      {
        stepName: 'Appointment Confirmation',
        content:
          'Once we have collected and reviewed your information, our medical professional will make sure that all required medical information is ready. We will send you a confirmation that the appointment has been successfully created.',
      },
      {
        stepName: 'Appointment Delivery',
        content:
          'Once the confirmation is sent, a text will be sent to schedule the best time for our staff to collect your specimen. The morning of the collection date we will send a reminder text and our mobile team will contact you when they are 30 minutes away from your home',
      },
    ].map((datum, index) => (
      <div className='d-flex flex-column ty-step position-relative'>
        <span
          className='ty-step-circle position-absolute'
          style={{
            background: index === 2 ? '#e8e8e9' : '#EE1C2C',
            boxShadow: index === 1 ? '0px 0px 14px red' : 'none',
          }}
        />
        {index !== 2 && (
          <span
            className='ty-step-line position-absolute'
            style={{ background: index === 0 ? '#EE1C2C' : '#e8e8e9' }}
          />
        )}

        <div className='font-size-lg ff-museosans-sm'>{datum.stepName}</div>
        <div>{datum.content}</div>
      </div>
    ));

  return (
    <div className='d-flex flex-column ty-wrapper'>
      <Text>Thanks! Your Appointment has been created!</Text>

      <div className='text-center m-3'>
        A Healthcare professional will call or text you shortly.
      </div>
      <div className='text-center text-danger m-3'>If this is an emergency, please call 911.</div>
      <div className='ff-museosans-bold m-3 ty-item-width-500 what-comes-next'>What comes next</div>
      <div className='d-flex flex-column ty-steps-wrapper ty-item-width-500 '>{renderSteps()}</div>
      <div
        style={{
          margin: '32px 0',
          justifyContent: 'center',
        }}
        className='m-t-24 d-flex align-items-center cursor-pointer'
        onClick={() => {
          goToDashboard();
        }}
      >
        <span className='text-danger ff-museosans-med m-1'>HOME</span>
        <img src={rightArrow} alt='right-arrow' style={{ width: 15, height: 15 }} />
      </div>
    </div>
  );
};

export default ThankyouPage;

import Select from 'react-select';
import './index.scss';

const customStyles = {
  control: (styles) => {
    return {
      ...styles,
      width: '100%',
      height: window.screen.width < 993 ? '60px' : '56px',
      borderRadius: '0px',
      border: 'none',
      borderBottom: '1px solid #000000',
      outline: 'none',
    };
  },
  indicatorSeparator: (styles) => {
    return {
      display: 'none',
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: 0,
    };
  },
};

const Dropdown = ({
  options = [],
  className = '',
  errorMessage = '',
  showError = false,
  ...rest
}) => {
  return (
    <div className={`d-flex flex-column w-100 ${className}`}>
      <Select options={options} className={`dropdown-wrapper`} styles={customStyles} {...rest} />
      {showError && <span style={{ color: 'red', fontSize: '14px' }}>{errorMessage}</span>}
    </div>
  );
};

export default Dropdown;

import React from 'react';
import './index.scss';

const StepSlider = ({ width = '100%', totalSteps = 10, completedSteps = 0 }) => {
  console.log('totalSteps', totalSteps, completedSteps);
  const individualStepWidth = Math.ceil(100 / totalSteps);
  return (
    <div
      style={{
        width,
      }}
      className='d-flex step-wrapper'
    >
      <div className='step' style={{ width: `${individualStepWidth * completedSteps}%` }} />
    </div>
  );
};

export default StepSlider;

import { useState } from 'react';

const OtpInput = ({ index, setActiveInput, moveLeft, moveRight, onEnterValue, onHandleEnter }) => {
  const [value, setValue] = useState('');
  return (
    <input
      type='number'
      value={value}
      key={`otp-field-${index}`}
      className='otp-input-field hello'
      maxLength={1}
      onClick={() => {
        setActiveInput(index);
      }}
      onChange={(e) => {
        if (e.target.value.length <= 1) {
          setValue(e.target.value);
          onEnterValue({ value: e.target.value, currentInputIndex: index });
        }
      }}
      onKeyDown={(e) => {
        if (e.key === '.' || e.key === 'e') {
          // to prevent "." from getting entered
          e.preventDefault();
          return;
        }
        if (e.key === 'ArrowLeft') moveLeft();
        else if (e.key === 'ArrowRight' || e.key === 'Tab') {
          e.preventDefault();
          moveRight();
        } else if (e.key === 'Enter') {
          onHandleEnter();
        } else if (e.key === 'Backspace') {
          setValue('');
          setTimeout(moveLeft, 10);
        }
      }}
    />
  );
};
export default OtpInput;
